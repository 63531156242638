import React from "react"
import parse, { Element } from "html-react-parser"
import { isDomReady } from "./utils"

export const renderTrackingScripts = (html, options) =>
  isDomReady &&
  document.getElementsByClassName(`${options.id}-${options.placement}`).length < 1 &&
  parse(html, {
    replace: domNode => {
      if (domNode instanceof Element && domNode.attribs) {
        if (domNode.type === `script`) {
          let script = document.createElement(`script`)
          if (domNode.attribs.src) script.src = domNode.attribs.src
          // @ts-ignore
          if (domNode.children.length) script.innerHTML = domNode.children[0].data
          script.id = `${options.id}-${options.placement}-${Math.random().toString(36).substring(9)}`
          script.className = `${options.id}-${options.placement}`
          script.type = `text/javascript`
          script.async = true
          script.defer = true
          if (options.placement === `head`) document.head.appendChild(script)
          if (options.placement === `body`) document.body.prepend(script)
          if (options.placement === `foot`) document.body.appendChild(script)
          return <React.Fragment key={options.placement} />
        }
      }
      return domNode
    },
  })
