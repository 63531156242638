import { gql } from "@apollo/client"

import { CART_FRAGMENT } from "@app/dotheadless/graphql/fragments/cartFragment"
import { DELIVERY_OPTION_FRAGMENT } from "@app/dotheadless/graphql/fragments/deliveryFragment"
import { MAILING_ADDRESS_FRAGMENT } from "@app/dotheadless/graphql/fragments/mailingAddressFragment"

export const GET_CART = gql`
  query GET_CART($cartId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cart(id: $cartId) {
      ... on Cart {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`

export const GET_SHIPPING_RATES = gql`
  query GET_SHIPPING_RATES($cartId: ID!) {
    cart(id: $cartId) {
      ... on Cart {
        deliveryGroups(first: 10) {
          edges {
            node {
              deliveryAddress {
                ...MailingAddressFragment
              }
              deliveryOptions {
                ...DeliveryOptionFragment
              }
              selectedDeliveryOption {
                ...DeliveryOptionFragment
              }
            }
          }
        }
      }
    }
  }
  ${DELIVERY_OPTION_FRAGMENT}
  ${MAILING_ADDRESS_FRAGMENT}
`