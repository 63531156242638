import React from "react"
import parse, { Element } from "html-react-parser"
import BlockContent from "@sanity/block-content-to-react"
import { Link } from "gatsby"

export const renderCode = html => {
  const elementId = Math.random().toString(36).substring(9)

  return parse(html, {
    replace: domNode => {
      if (domNode instanceof Element && domNode.attribs) {
        if (domNode.type === `script`) {
          let script = document.createElement(`script`)
          if (domNode.attribs.src) script.src = domNode.attribs.src
          // @ts-ignore
          if (domNode.children.length) script.innerHTML = domNode.children[0].data
          script.id = `code-${elementId}`
          script.className = `code-${elementId}`
          script.type = `text/javascript`
          script.async = true
          script.defer = true
          document.body.prepend(script)
          return <React.Fragment key={elementId} />
        }
      }
      return domNode
    },
  })
}

const serializers = {
  marks: {
    document: ({ children, mark }) => (
      <Link to={mark?.document?.link || ""} title={mark?.document?.title}>
        {children}
      </Link>
    ),
    link: ({ children, mark }) => (
      <a href={mark?.link?.link} title={mark?.link?.title} target={mark?.link?.external ? "_blank" : ""}>
        {children}
      </a>
    ),
    color: ({ children, mark }) => <span style={{ color: mark?.hex }}>{children}</span>,
  },
  types: {
    code: ({ node }) => renderCode(node.code),
    image: ({ node }) => <img src={node?.asset?.url} />,
    customImage: ({ node }) => <img alt={node?.alt} src={node?.asset?.url} />,
  },
}

export const sanitySerializers = customSerializers => ({
  marks: {
    ...serializers?.marks,
    ...(customSerializers?.marks || {}),
  },
  types: {
    ...serializers?.types,
    ...(customSerializers?.types || {}),
  },
})

export const sanityContent = (content, customSerializers = null) =>
  content ? <BlockContent className={"rte"} blocks={content} serializers={customSerializers || serializers} /> : content
