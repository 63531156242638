import React, { Suspense } from "react"
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary"

const isDevelopment = (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development") === "development"
const isBrowser = typeof window !== `undefined`

export const ErrorBoundary = ({ children }) =>
  isBrowser ? (
    <ReactErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) =>
        isDevelopment ? (
          <div role={`alert`} style={{ border: `3px #aaa dashed`, padding: `1rem` }}>
            <pre>
              <strong>🐛 Component error</strong>
            </pre>
            <pre style={{ background: `#eee`, marginTop: `1rem`, padding: `1rem` }}>{error.message}</pre>
            <button onClick={resetErrorBoundary} style={{ background: `black`, color: `white`, marginTop: `1rem`, padding: `1rem` }}>
              Reload Component
            </button>
          </div>
        ) : (
          <></>
        )
      }
      onError={error => console.error(error.message)}
    >
      <Suspense fallback={false}>{children}</Suspense>
    </ReactErrorBoundary>
  ) : (
    <>{children}</>
  )
