import store from "store"

export const storage = {
  get: key => {
    const stored = store.get(key)
    if (!stored) return null

    try {
      const item = JSON.parse(stored)
      const now = new Date()

      if (item?.expiry && now.getTime() > item?.expiry) {
        store.remove(key)
        return null
      }

      return item?.value
    } catch (e) {
      return stored
    }
  },
  set: (key, value, days = 0) => {
    const now = new Date()
    const expiry = days && now.getTime() + days * 86400000

    const item = {
      value,
      expiry,
    }

    store.set(key, JSON.stringify(item))
  },
  setAll: items => items.map(item => store.set(item?.key, item?.value)),
  remove: key => store.remove(key),
  wipe: () => store.clearAll(),
  all: () => {
    const output = []
    store.each((value, key) => {
      output.push({ key, value })
    })
    return output
  },
}
