import React from "react"
import { Text, Stack, StackProps, HStack } from "@chakra-ui/react"
import { useAppContext } from "@providers/app"
import { useShopifyPrice } from "@hooks/useShopify"

export interface ProductPriceProps {
  variant?: any
  loading?: boolean
  direction?: StackProps["direction"]
  updatedQty?: number
  discounts?: [any]
  isBase?: boolean
}

const ProductPrice = ({ variant, direction = "column", updatedQty = 1, discounts, isBase = false }: ProductPriceProps) => {
  const { activeVariant } = useAppContext()
  const itemVariant = variant ?? activeVariant

  const { formattedPrice, formattedCompareAtPrice, onSale, price } = useShopifyPrice(itemVariant, updatedQty)
  // const discountsToShow = React.useMemo(() => {
  //   return discounts?.filter?.(discount => {
  //     return discount?.discountApplication?.targetSelection !== "ALL"
  //   })
  // }, [discounts])

  const totalDiscount = React.useMemo(() => {
    // reduce all valid discounts into 1 discount amount
    return discounts?.reduce?.(
      (acc, discount) => {
        return {
          discountedAmount: {
            amount: acc?.discountedAmount?.amount + Number(discount?.discountedAmount?.amount),
          },
        }
      },
      {
        discountedAmount: {
          amount: 0,
        },
      }
    )
  }, [discounts])

  const isDiscount = React.useMemo(() => {
    return discounts?.length
  }, [discounts])

  const DiscountPrice = () => {
    return (
      <Stack>
        <HStack display={"flex"} flexFlow={"row"}>
          <Text fontWeight={"regular"} color={"text.primary"} fontSize={isBase ? "14px" : "16px"}>
            ${(Math.round((price - totalDiscount?.discountedAmount?.amount) * 100) / 100)?.toFixed(2)}
          </Text>
          <Text
            color="text.secondary"
            as="s"
            lineHeight={isBase ? "23px" : "24px"}
            fontSize={isBase ? "14px" : "16px"}
          >{`${formattedPrice}`}</Text>
        </HStack>
        {isBase ? null : (
          <Text color="text.secondary" size={"xs"}>
            Save ${Number(totalDiscount?.discountedAmount?.amount)?.toFixed?.(2)} off RRP
          </Text>
        )}
      </Stack>
    )
  }

  return isDiscount ? (
    <>{DiscountPrice()}</>
  ) : (
    <Stack spacing={direction === "column" ? 0 : 1} direction={direction} marginTop={isBase ? "0!important" : ""} alignItems={"center"}>
      {onSale ? (
        <Text color="text.secondary" as="s" fontSize={isBase ? "14px" : "16px"}>
          {formattedCompareAtPrice}
        </Text>
      ) : null}
      {formattedPrice ? (
        <Text
          fontWeight={onSale ? "semibold" : "regular"}
          lineHeight={isBase ? "23px" : "24px"}
          fontSize={isBase ? "14px" : "16px"}
          color={onSale ? "brand.sale" : "text.primary"}
        >
          {formattedPrice}
        </Text>
      ) : null}
    </Stack>
  )
}
export default React.memo(ProductPrice)
