import React, { useState, useEffect, useMemo } from "react"
import { Script } from "gatsby"
import { Helmet } from "react-helmet"
import { useMeta } from "@hooks/useMeta"
import { useConfigContext } from "@providers/config"
import { getCookieConsentValue } from "react-cookie-consent"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useTrackingContext } from "@app/providers/tracking"

import type { Location } from "@root/types/global"
import { useCustomerContext } from "@app/providers/customer"

type Props = {
  breadcrumbs?: Array<any>
  data: any
  location: Location
}

const Meta: React.FC<Props> = ({ breadcrumbs = [], data, location }) => {
  const { getData, getLanguages, getSchemas, getTags, getTracking } = useMeta()
  const {
    settings: { routes },
    store: { googleMetaVerification, klaviyoPublicKey },
  } = useConfigContext()
  const { trackGDPRConsent } = useAnalytics()

  const url = location?.pathname
  const languages = getLanguages(url)
  const metadata = getData({
    url,
    data,
    routes,
    breadcrumbs,
    template: data?.template?.metadata,
    language: languages?.find(({ primary }) => primary)?.language?.split("_")?.[0] || "",
  })
  const metatags = getTags(metadata)
  const schemas = getSchemas(metadata)
  const { tracked } = useTrackingContext()
  const tracking = getTracking()
  const { customer } = useCustomerContext()

  const [canonical, setCanonical] = useState(metadata?.canonical)

  useEffect(() => {
    const currentPage = typeof window !== "undefined" ? window.location?.search : null
    const canonicalWithPage = `${metadata?.canonical}${currentPage}`

    setCanonical(currentPage ? canonicalWithPage : metadata?.canonical)
  }, [metadata?.canonical, typeof window !== "undefined" && window.location?.search])
  const gdprCookieName = "CookieConsent"

  useEffect(() => {
    if (tracked) {
      if (getCookieConsentValue(gdprCookieName) === "true") {
        trackGDPRConsent()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracked])

  return (
    <>
      <Helmet htmlAttributes={{ lang: metadata?.metaLang }} title={metadata?.title || data?.collection?.shopify?.seoTitle || data?.product?.shopify?.seoTitle} meta={metatags}>
        <link href={metadata?.siteURL} rel="home" />
        <link href={canonical} rel="canonical" />
        <link href="//apis.google.com/" rel="dns-prefetch" />
        <link href="//www.googletagmanager.com/" rel="dns-prefetch" />
        <meta name="description" content={metadata?.description || data?.collection?.shopify?.seoDescription || data?.product?.shopify?.seoDescription} />
        
        {languages?.map(({ key, href, hrefLang, rel }) => <link key={key} href={href} hrefLang={hrefLang} rel={rel} />)}

        {schemas.map((schema, index) => (
          <script type="application/ld+json" key={index}>
            {JSON.stringify(schema)}
          </script>
        ))}

        <Script
          dangerouslySetInnerHTML={{
            __html: `(function e(){var e=document.createElement("script");e.type="text/javascript",e.async=true,e.src="//staticw2.yotpo.com/qBOhv2KappFEvo6JObL2Jz7t53zS4u4ejoRDONTb/widget.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)})();`,
          }}
        />

        {googleMetaVerification && <meta name="google-site-verification" content={`${googleMetaVerification}`} />}

        <script>
          {customer?.email &&
            `var klaviyo = klaviyo || [];
                klaviyo.push(['identify', {
                  '$email' : '${customer?.email}'
                }]);`}
        </script>
        {/* Yotpo widgets / Rewards page */}
        <script src="https://cdn-widgetsrepository.yotpo.com/v1/loader/u4YB5yJZnOd7mvhMh6fUZg" async></script>
      </Helmet>

      <Script
        src="https://cdn-widgetsrepository.yotpo.com/v1/loader/qBOhv2KappFEvo6JObL2Jz7t53zS4u4ejoRDONTb?languageCode={{ localization.language.iso_code | escape }}"
        async
      />
      <Script src="//cdn.shopify.com/shopifycloud/shop-js/client.js" strategy="idle" />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
            console.log('Yotpo script is loading...');
            var yotpoIsReady = false;
            (function checkForYotpo() {
              setTimeout(function() {
                if (typeof window.yotpoWidgetsContainer !== "undefined") {
                    yotpoIsReady = true;
                    window.yotpoWidgetsContainer.initWidgets()
                    console.log('Yotpo reloaded');
                } else {
                  console.log('Checking for Yotpo...');
                }
                if (yotpoIsReady === false) checkForYotpo();
              }, 10000);
            })();
          `,
        }}
      />

    <script dangerouslySetInnerHTML={{
      __html: `
        !function(i,n,s,t,a,u,d){i.InstantConfig=i.InstantConfig||{},d=i.InstantJS=i.InstantJS||{},d.trackQueue=[],d.track=function(){d.trackQueue.push(arguments)},u=n.createElement(s),u.async=!0,u.src=t,a=n.getElementsByTagName(s)[0],a.parentNode.insertBefore(u,a)}(window,document,'script','https://cdn.instant.one/instant.js?siteId=site_bcbf9163488c4190842a626b77f2fe62');
      `,
    }} />

      <script async src="https://t.cfjump.com/tag/65707"></script>

      {tracking.map(script => script)}
    </>
  )
}

export default React.memo(Meta)
