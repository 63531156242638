import gql from "graphql-tag"

import { CART_FRAGMENT } from "@app/dotheadless/graphql/fragments/cartFragment"
import { CART_USER_ERROR_FRAGMENT } from "@app/dotheadless/graphql/fragments/cartUserErrorFragment"

export const CART_ATTRIBUTES_UPDATE = gql`
  mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_CREATE = gql`
  mutation CART_CREATE($input: CartInput!, $countryCode: CountryCode!, $languageCode: LanguageCode)
  @inContext(country: $countryCode, language: $languageCode) {
    cartCreate(input: $input) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_BUYER_IDENTITY_UPDATE = gql`
  mutation cartBuyerIdentityUpdate($buyerIdentity: MailingAddressInput!, $cartId: ID!) {
    cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_DISCOUNT_CODES_UPDATE = gql`
  mutation cartDiscountCodesUpdate($discountCodes: [String!]!, $cartId: ID!) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_LINE_ADD = gql`
  mutation cartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_LINE_UPDATE = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_LINE_REPLACE = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`

export const CART_LINE_REMOVE = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFragment
      }
      userErrors {
        ...CartUserErrorFragment
      }
    }
  }
  ${CART_FRAGMENT}
  ${CART_USER_ERROR_FRAGMENT}
`