import React from "react"
import { HStack, MenuButton, Grid, Text, StackDivider, Box, LinkBox, LinkOverlay, Menu } from "@chakra-ui/react"

import Link from "@components/Link"
import { colors } from "@app/theme/colors"
import Image from "@components/Image/Image"
import Icon from "@components/Icon/Icon"
import {
  LeftColumn,
  LinkGrid,
  MenuOverlay,
  RightColumn,
  SubMenuTitle,
  SubNavigationMenu,
  TileButton,
  MenuContainer,
} from "./NavigationHeaderDesktopStyles"

const renderMenuItem = (index: Number, item: MenuItem, setActiveSubMenu: React.Dispatch<React.SetStateAction<number>>) => {
  switch (item.type) {
    case "sub":
      return (
        <>
          <Text size="lg" onMouseEnter={() => setActiveSubMenu(index)}>
            {item.title}
          </Text>
          <Icon name="chevronRight" size="24px" color="text.icon" />
        </>
      )
    case "link":
      return (
        <Text as={Link} to={item.url}>
          {item.title}
        </Text>
      )
    default:
      return <></>
  }
}

const renderSubMenuItem = (item: MenuItem) => {
  switch (item.type) {
    case "sub":
      return (
        <>
          <SubMenuTitle size="sm">{item.title}</SubMenuTitle>
          <LinkGrid>
            {item.items.map((item, index) => (
              <Text key={index} as={Link} to={item.url} color="text.icon" whiteSpace="nowrap">
                {item.title}
              </Text>
            ))}
          </LinkGrid>
        </>
      )
    case "tile":
      return (
        <LinkBox>
          <LinkOverlay as={Link} to={item.url}>
            <Box position="relative">
              <Image src={item.image} alt="Nav menu image" w="267px" h="334px" />
              {item.url && item.toggleCTAButton && <TileButton variant="blue">{item.title}</TileButton>}
            </Box>
          </LinkOverlay>
        </LinkBox>
      )
    default:
      return <></>
  }
}

const NavigationHeaderDesktopItem = ({ title, url, accent, items, active, handleActive }: MenuItemDesktop) => {
  const key = title.toString()
  const [activeSubMenu, setActiveSubMenu] = React.useState<number>(0)

  return (
    <Menu>
      <MenuButton
        key={key}
        pointerEvents="initial"
        onMouseEnter={() => handleActive(key)}
        onMouseLeave={() => handleActive(null)}
        onClick={() => handleActive(null)}
        as={Link}
        to={url}
        px={2.5}
        py={2}
      >
        <Text size="lg" color={accent === "sale" ? "brand.sale" : "text.primary"}>
          {title}
        </Text>
      </MenuButton>
      <Box py={2} boxShadow={active === title ? `inset 0 -2px 0 ${colors.brand.navy.dark}` : "none"}>
        {items && !!items.length && (
          <MenuContainer
            display={active === key ? "block" : "none"}
            onClick={() => handleActive(null)}
            onMouseEnter={() => handleActive(key)}
          >
            <SubNavigationMenu onMouseEnter={() => handleActive(key)} onMouseLeave={() => handleActive(null)}>
              <Grid as="nav" templateColumns="25% 75%">
                <LeftColumn spacing={2}>
                  {items.map((item, index) => (
                    <HStack key={index} justifyContent="space-between" textDecor={index === activeSubMenu ? "underline" : "none"}>
                      {renderMenuItem(index, item, setActiveSubMenu)}
                    </HStack>
                  ))}
                </LeftColumn>
                <RightColumn divider={<StackDivider />}>
                  {items[activeSubMenu]?.items?.map((item, index) => <Box key={index}>{renderSubMenuItem(item)}</Box>)}
                </RightColumn>
              </Grid>
            </SubNavigationMenu>
            <MenuOverlay />
          </MenuContainer>
        )}
      </Box>
    </Menu>
  )
}

export default React.memo(NavigationHeaderDesktopItem)
