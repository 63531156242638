export const isBrowser = typeof window !== "undefined"

export const isDomReady =
  isBrowser &&
  navigator?.userAgent &&
  !navigator.userAgent.match(
    /nux.*oto\sG|x11.*fox\/54|x11.*ome\/39|x11.*ome\/62|oid\s6.*1.*xus\s5.*MRA58N.*ome|JWR66Y.*ome\/62|woobot|speed|ighth|tmetr|eadle/i
  )

export const isDev = process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development"

export const encodeBase64 = string => {
  try {
    return btoa(string)
  } catch (err) {
    return string
  }
}

export const decodeBase64 = string => {
  try {
    return atob(string)
  } catch (err) {
    return string
  }
}

export const decodeShopifyId = (id, type) => {
  return id ? decodeBase64(id).split(`${type}/`)[1] : id
}

export const encodeShopifyId = (id, type) => {
  return id ? encodeBase64(`gid://shopify/${type}/${id}`) : id
}

export const getUrlParameter = (name, location = (isBrowser && window?.location) || null) => new URLSearchParams(location?.search)?.get(name)

export const setUrlParameter = (name = "", value = "", location = (isBrowser && window?.location) || null) => {
  const url = new URLSearchParams(location?.search)
  name && value ? url?.set(name, value) : name && url?.delete(name)
  return `${location?.pathname}${url?.toString() ? `?${url?.toString()}` : ""}`
}

export const capitalise = string => (typeof string === "string" ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : ``)

export const handleize = string =>
  string
    .toLowerCase()
    .replace(/[^\w\u00C0-\u024f]+/g, "-")
    .replace(/^-+|-+$/g, "")

export const formatPrice = price => parseFloat(price?.replace(/,/g, "."))?.toFixed(2) || 0

export const pollGlobal = (key: string, callback: () => void) => {
  if (isBrowser) {
    if (window[key]) {
      callback()
    } else {
      setTimeout(() => {
        pollGlobal(key, callback)
      }, 100)
    }
  }
}

export const edgeNormaliser = (item: any) => item?.edges?.map(({ node }: { node: any }) => node) || item || [item] || []

export const linebreak = (content?: string) => content?.replace(/(?:\r\n|\r|\n)/g, "<br />")
