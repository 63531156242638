module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__encoding@0.1.1_vduw475kogortldene3pfiuuxm/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","name":"Purebaby","short_name":"Purebaby","description":"When I couldn’t find a range to meet all my needs — fashionable, organic, functional and most importantly affordable — I was inspired to create it myself.","background_color":"#FFFFFF","theme_color":"#000000","display":"minimal-ui","icon":"static/images/icon.jpg","icons":[{"src":"static/images/icon.jpg","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3a88050d2a094e3078a55720a2546e2"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__encoding@0._bk36kowbcezmwg7jyri5oicaqi/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__encoding@0.1.13_react-dom@18.3.1_react@18.3._6c7ov7r3ibtf2hbppphou477km/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
