module.exports = {
  id: "purebaby-stage",
  title: "Purebaby (Staging)",
  description:
    "When I couldn not find a range to meet all my needs — fashionable, organic, functional and most importantly affordable — I was inspired to create it myself.",
  url: "purebabystaging.gatsbyjs.io",

  locationRegion: "AU",
  locationCountries: "AU",
  locationForceRegion: true,
  locationLookupUrl: "https://ipapi.co/json/",

  shopifyPlus: true,
  shopifyApiVersion: "2025-01",
  shopifyShopDomain: "purebaby-stage.myshopify.com",
  shopifyCheckoutUrl: "purebaby-stage.myshopify.com",
  shopifyStorefrontToken: "3976a785f7dea4367eb05b7eab469066",
  shopifyAdminToken: "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",
  shopifyMultiPassToken: "cfca5fb0d52d8d147d9ab2068a76f8aa",

  sanityProjectId: "kaeaimuz",
  sanityDataset: "staging",
  sanityApiVersion: "2022-02-24",
  sanityToken:
    "skwy5tCO4pDJNSldUm52qbtykX6Q2K7kU4oprIi57uc0NvMJKYArr3oJDvpJqsXo0nrebN9xSeig2h9PzVwOYrlxEQzfzdpHl07IhUTWgBrsU1WLfP5thRlwf36lPw2BwJjUkjr0bD7Btl9ihxvmprU4WXeEopUO3zBxRMaemk3Dq6ToHMM8",

  searchIndex: "purebaby-stage",
  searchConfig: "https://config.search.reactify.app/?shop=purebaby-stage.myshopify.com",
  gtm: "GTM-P457CB",
  googleAuthClientId: "",
  googleMapsApiKey: "AIzaSyC2pbhg5eMUosOwcUVcn0RWuYaqbdGMXmc",
  googleMapsApiVersion: "3.37",
  api: {
    ap21CheckStock: "https://purebaby-stage.myshopify.com/apps/dotapparel21/api-checkStock",
    dapLoyalty: "https://purebaby-dotapparel-loyalty-au.web.app",
    yotpo: "https://loyalty.yotpo.com",
  },

  yotpo: {
    yotpoApiKey: "6Gs5n1kS4gC033hQYWocqAtt",
    yotpoGUID: "u4YB5yJZnOd7mvhMh6fUZg",
    downloadActionName: "Download the Purebaby App",
  },

  klaviyoApiKey: "",
  klaviyoPublicKey: "Xv7Kmh",

  emailSender: "wholesale@purebaby.com.au",

  facebook: {
    facebookAppId: "321418229190246",
  },
}
