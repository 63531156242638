import { decodeBase64 } from "./utils"

const schemaTypes = (data, mapping) => Object.keys(mapping).find(key => data?.hasOwnProperty(key)) || ""

export const schemaContent = metadata => (metadata?.schema && metadata?.schema(metadata)) || schemaWebSite(metadata)

export const schemaDocument = (data, mapping) => (data?.hasOwnProperty(schemaTypes(data, mapping)) ? data[schemaTypes(data, mapping)] : {})

export const schemaTemplate = (template = null, item = null, templateMapping = null): any => {
  const mapping = templateMapping || { title: item?.title || "", description: item?.description?.split(".")[0] || "" }
  const regex = new RegExp(
    Object.keys(mapping)
      .map(key => `\{${key}\}`)
      .join("|"),
    "gi"
  )

  return template
    ? Object.entries(template).reduce(
        (obj, [key, value]: any) => ({
          ...obj,
          [key]: value.replace(regex, item => mapping[item.replace(/[{}]/g, "")]),
        }),
        {}
      )
    : null
}

export const schemaData = (
  {
    breadcrumbs,
    data,
    global,
    language,
    organisation = null,
    preferences = null,
    routes,
    social,
    template = null,
    templateMapping = null,
    tracking,
    url,
  },
  mapping: any
) => {
  const mappedDocument = schemaDocument(data, mapping)
  const document = {
    ...mappedDocument,
    ...(mappedDocument?.shopify?.raw && { ...JSON.parse(mappedDocument?.shopify?.raw) }),
  }
  const templated = schemaTemplate(template, document, templateMapping)
  const schema = mapping[schemaTypes(data, mapping)] || false
  const type = schemaTypes(data, mapping) || "website"
  const sanityMeta = document?.metadata || data?.content?.edges[0]?.node?.metadata || {}
  const shopifyMeta = document?.shopify || data?.content?.edges[0]?.node?.shopify || {}
  const meta = {
    ...sanityMeta,
    title: sanityMeta?.title || shopifyMeta?.seoTitle,
    description: sanityMeta?.description || shopifyMeta?.seoDescription,
  }
  const org = organisation || preferences
  const separator = org?.separator || `|`
  const metaLang = language || "en_AU"
  const siteName = org?.title || ""
  const alternateSiteName = org?.alternateTitle || ""
  const siteDescription = org?.description || ""
  const siteURL = org?.url || ""
  const pageTitle = meta?.title || templated?.title || document?.title || global?.title
  const title = pageTitle !== siteName && pageTitle && siteName ? `${pageTitle} ${separator} ${siteName}` : siteName || ""
  const description = meta?.description || templated?.description || document?.description || global?.description || siteDescription || ""
  const pageUrl = `${siteURL}${url}` || ""
  const author = org?.author || ""
  const robots = meta?.noIndex ? "noindex, nofollow" : "all"
  const canonical = meta?.canonicalUrl ? `${siteURL}${meta?.canonicalUrl}` : pageUrl
  const facebookAppID = tracking?.facebookAppId || ""
  const googleSiteVerifications = tracking?.googleSiteVerifications?.length
    ? tracking.googleSiteVerifications
    : tracking?.googleSiteVerify
    ? [tracking.googleSiteVerify]
    : []
  const searchURL = `${siteURL}${routes?.SEARCH || "/"}`

  const socialProfiles = social?.channels?.map((channel: any) => channel?.url) || []
  const twitterUser =
    social?.channels
      ?.filter((channel: any) => channel?.title?.toLowerCase().includes("twitter"))
      .map((channel: any) => channel?.url.replace("https://twitter.com/", "")) || ""
  const siteImage = schemaImage({
    alt: `${siteName}`,
    url: `${org?.logo?.asset?.url}`,
  })
  const address = schemaAddress({
    ...organisation,
  })
  const image = schemaImage({
    alt: `${title}`,
    url: `${meta?.image?.asset?.url || org?.logo?.asset?.url}`,
  })

  const listItems = [
    schemaListItem({ name: "Homepage", url: siteURL, position: 1 }),
    ...(breadcrumbs?.length
      ? breadcrumbs.map(({ name, position, url }) =>
          listItems.push(
            schemaListItem({
              name,
              url: `${siteURL}${url}`,
              position: position || listItems.length + 1,
            })
          )
        )
      : []),
  ]
  listItems.push(schemaListItem({ name: title, url: pageUrl, position: listItems.length + 1 }))

  return {
    schema,
    document,
    type,
    metaLang,
    siteName,
    alternateSiteName,
    siteURL,
    title,
    description,
    pageUrl,
    author,
    robots,
    canonical,
    facebookAppID,
    googleSiteVerifications,
    socialProfiles,
    twitterUser,
    siteImage,
    image,
    address,
    searchURL,
    listItems,
  }
}

export const schemaOrg = ({ address = {}, siteName, siteImage, siteURL, socialProfiles }) => ({
  "@context": "http://schema.org",
  "@type": "Organization",
  url: siteURL,
  name: siteName,
  address,
  logo: siteImage,
  sameAs: socialProfiles,
})

export const schemaAddress = ({ addressLocality, addressRegion, postalCode, streetAddress }) => ({
  "@type": "PostalAddress",
  addressLocality: addressLocality || "",
  addressRegion: addressRegion || "",
  postalCode: postalCode || "",
  streetAddress: streetAddress || "",
})

export const schemaImage = image => ({
  "@type": "ImageObject",
  description: image.alt || "",
  url: image.url || "",
})

export const schemaListItem = ({ url, name, position }) => ({
  "@type": "ListItem",
  item: {
    "@id": url,
    name,
  },
  position,
})

export const schemaBreadcrumbs = ({ listItems }) => ({
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  description: "Breadcrumbs list",
  name: "Breadcrumbs",
  itemListElement: listItems,
})

export const schemaWebSite = ({ description, metaLang, author, siteName, alternateSiteName, siteURL, searchURL }) => ({
  "@context": "http://schema.org",
  "@type": "WebSite",
  url: siteURL,
  inLanguage: metaLang,
  mainEntityOfPage: siteURL,
  description: description,
  name: siteName,
  alternateName: alternateSiteName,
  author: schemaPerson({ name: author }),
  creator: schemaPerson({ name: author }),
  publisher: schemaPerson({ name: author }),
  copyrightYear: new Date().getFullYear(),
  copyrightHolder: schemaPerson({ name: author }),
  potentialAction: {
    "@type": "SearchAction",
    target: `${searchURL}?q={query}`,
    "query-input": "required name=query",
  },
})

export const schemaSearch = ({ title, url, searchURL, document }) => ({
  "@context": "http://schema.org",
  "@type": "WebSite",
  url: url,
  name: title,
  potentialAction: {
    "@type": "SearchAction",
    target: `${searchURL}?q={query}`,
    "query-input": "required name=query",
    agent: schemaPerson({ name: document?.authUser?.firstname || `Guest` }),
    query: document?.query || "",
  },
})

export const schemaProduct = ({ title, description, url, image, siteURL, document }) => ({
  "@context": "http://schema.org",
  "@type": "Product",
  name: title,
  url: url,
  description: description,
  gtin13: decodeBase64(document?.id)?.replace("gid://shopify/Product/", ""),
  image: image,
  brand: {
    "@type": "Thing",
    name: document?.vendor,
  },
  sku: document?.variants && document?.variants.length ? document?.variants[0].sku : "",
  offers: {
    "@type": "AggregateOffer",
    highPrice: document?.priceRange?.maxVariantPrice?.amount || "",
    lowPrice: document?.priceRange?.minVariantPrice?.amount || "",
    priceCurrency: document?.priceRange?.maxVariantPrice?.currencyCode || "",
    offerCount: document?.variants?.length,
    offers: document?.variants ? document?.variants?.map(variant => schemaOffer({ siteURL, variant: { ...variant }, handle: document.handle })) : [],
  },
  keywords: [],
})

export const schemaBlog = ({ title, url, siteName, siteImage, siteURL, socialProfiles }) => ({
  "@context": "http://schema.org",
  "@type": "Blog",
  name: title,
  url: url,
  publisher: schemaOrg({ siteName, siteImage, siteURL, socialProfiles }),
  keywords: [],
})

export const schemaArticle = ({ title, description, metaLang, author, url, image, siteName, siteImage, siteURL, socialProfiles, document }) => ({
  "@context": "http://schema.org",
  "@type": "Article",
  articleBody: description,
  author: schemaPerson({
    name: document?.attributes?.author?.name || author,
  }),
  copyrightHolder: schemaPerson({
    name: document?.attributes?.author?.name || author,
  }),
  copyrightYear: document?._createdAt ? document?._createdAt.split(`-`)[0] : "",
  creator: schemaPerson({
    name: document?.attributes?.author?.name || author,
  }),
  dateModified: document?._createdAt || "",
  datePublished: document?._createdAt || "",
  description: description,
  headline: title,
  image: image,
  inLanguage: metaLang,
  publisher: schemaOrg({ siteName, siteImage, siteURL, socialProfiles }),
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id": siteURL,
  },
  name: title,
  url: url,
})

export const schemaStore = ({ title, description, image, author, url, document }) => ({
  "@context": "http://www.schema.org",
  "@type": "Store",
  address: document?.address || "",
  brand: {
    "@type": "Brand",
    name: author,
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: document?.location?.lat,
    longitude: document?.location?.lng,
  },
  description: description,
  email: document?.email || "",
  image: image,
  name: title,
  telephone: document?.phone || "",
  url: url,
})

export const schemaPerson = ({ name }) => ({
  "@type": "Person",
  name: name || "",
})

export const schemaOffer = ({ siteURL, variant, handle }) => ({
  "@type": "Offer",
  availability: `http://schema.org/${variant?.availableForSale ? `InStock` : `OutOfStock`}`,
  price: variant?.priceV2?.amount || "",
  priceCurrency: variant?.priceV2?.currencyCode || "",
  sku: variant?.sku,
  url: `${siteURL}/products/${handle}?variant=${decodeBase64(variant?.id)?.replace("gid://shopify/ProductVariant/", "")}`,
})
