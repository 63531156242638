import * as GQL from "./graphql"
import * as Helpers from "./helpers"

export const DotHeadless = {
  graphql: {
    ...GQL,
  },
  helpers: {
    ...Helpers,
  },
}

export interface DotHeadless {
  graphql: {
    fragments: any
    mutations: any
    queries: any
  }
  helpers: {
    sanityContent: (content: any) => JSX.Element
    schemaContent: (metadata: any) => JSX.Element
    schemaData: (data: any) => any
    schemaOrg: (data: any) => any
    schemaImage: (data: any) => any
    schemaListItem: (data: any) => any
    schemaBreadcrumbs: (data: any) => any
    schemaWebSite: (data: any) => any
    schemaSearch: (data: any) => any
    schemaProduct: (data: any) => any
    schemaBlog: (data: any) => any
    schemaArticle: (data: any) => any
    schemaStore: (data: any) => any
    schemaPerson: (data: any) => any
    schemaOffer: (data: any) => any
    metaTags: (metadata: any) => Array<{
      name?: string
      property?: string
      content: string
    }>
    encodeBase64: (data: any) => string
    decodeBase64: (data: any) => string
    decodeShopifyId: (id: string, type: string) => string
    encodeShopifyId: (id: string, type: string) => string
    formatPrice: (price: string) => string
    getUrlParameter: (name: string, location: Location | null) => string
    setUrlParameter: (name: string, value: string, location: Location | null) => string
    renderTrackingScripts: (html: any, options: any) => any
    isDomReady: () => boolean
    isBrowser: () => boolean
    storage: {
      get: (key: string) => string
      set: (key: string, value: string) => void
      remove: (key: string) => void
      wipe: () => void
      all: () => Array<any>
    }
    ErrorBoundary: (props: any) => JSX.Element
  }
}
