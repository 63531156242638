import gql from "graphql-tag"

import { PRODUCT_FRAGMENT } from "../fragments/productFragment"
import { VARIANT_FRAGMENT, VARIANT_FRAGMENT_AVAILABILITY } from "../fragments/variantFragment"
import { IMAGE_FRAGMENT } from "../fragments/imageFragment"

export const GET_PRODUCT = gql`
  query (
    $countryCode: CountryCode!
    $handle: String!
    $parentQuery: String
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
    $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    grouped: products(query: $parentQuery, first: $firstVariants) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PRODUCT_AVAILABILITY = gql`
  query ($countryCode: CountryCode!, $handle: String!) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      tags
      variants(first: 100) {
        edges {
          node {
            ...VariantFragmentAvailability
          }
        }
      }
    }
  }
  ${VARIANT_FRAGMENT_AVAILABILITY}
`

export const GET_PRODUCT_LITE = gql`
  query ($countryCode: CountryCode!, $handle: String!, $firstImages: Int, $firstVariants: Int) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      id
      handle
      description
      title
      tags
      images(first: $firstImages) {
        edges {
          node {
            ...ImageFragment
          }
        }
      }
      variants(first: $firstVariants) {
        edges {
          node {
            ...VariantFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`

export const GET_PRODUCT_STANDARD = gql`
  query (
    $countryCode: CountryCode!
    $id: ID!
    $handle: String!
    $parentQuery: String
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
    $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    grouped: products(query: $parentQuery, first: $firstVariants) {
      edges {
        node {
          handle
          tags
        }
      }
    }
    recommendations: productRecommendations(productId: $id) {
      handle
      tags
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PRODUCT_COMPLETE = gql`
  query (
    $countryCode: CountryCode!
    $id: ID!
    $handle: String!
    $parentQuery: String
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
    $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
  ) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      ...ProductFragment
    }
    grouped: products(query: $parentQuery, first: $firstVariants) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
    recommendations: productRecommendations(productId: $id) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PRODUCTS_BY_HANDLE = handles => gql`
  query(
    $countryCode: CountryCode!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstVariants: Int
    $afterVariants: String
    $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
  ) @inContext(country: $countryCode) {
    ${handles?.map(
      handle => `
      product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        ...ProductFragment
      }
    `
    )}
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PRODUCTS_BY_HANDLE_LIGHT = handles => gql`
  query(
    $countryCode: CountryCode!
    $firstImages: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    ${handles?.map(
      handle => `
      product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        id
        handle
        title
        tags
        images(first: $firstImages) {
          edges {
            node {
              ...ImageFragment
            }
          }
        }
        variants(first: $firstVariants) {
          edges {
            node {
              ...VariantFragment
            }
          }
        }
      }
    `
    )}
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`

export const GET_PRODUCT_SIBLINGS_BY_HANDLE = handles => gql`
  query(
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    ${handles?.map(
      handle => `
        product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
          id
          handle
          title
          tags
          availableForSale
        }
      `
    )}
  }
`

export const GET_PRODUCT_IMAGES_BY_HANDLE = gql`
  query ($countryCode: CountryCode!, $handle: String!, $firstImages: Int) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      images(first: $firstImages) {
        edges {
          node {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`

export const GET_POPULAR_PRODUCTS = gql`
  query (
    $countryCode: CountryCode!
    $first: Int!
    $firstImages: Int!
    $firstMedia: Int!
    $firstCollections: Int!
    $firstVariants: Int!
    $afterVariants: String
    $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
  ) @inContext(country: $countryCode) {
    products(first: $first, sortKey: BEST_SELLING) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_PRODUCT_RECOMMENDATIONS = gql`
  query (
    $countryCode: CountryCode!
    $id: ID!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int!
    $firstVariants: Int
    $afterVariants: String
    $metafieldIdentifiers: [HasMetafieldsIdentifier!]!
  ) @inContext(country: $countryCode) {
    recommendations: productRecommendations(productId: $id) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`

export const GET_CART_RECOMMENDATIONS = ids => gql`
  query(
    $countryCode: CountryCode!
    $firstImages: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    ${ids?.map(
      (id, index) => `
      product${index}: productRecommendations(productId: "${id}") {
        id
        handle
        title
        tags
        images(first: $firstImages) {
          edges {
            node {
              ...ImageFragment
            }
          }
        }
        variants(first: $firstVariants) {
          edges {
            node {
              ...VariantFragment
            }
          }
        }
      }
    `
    )}
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`
