export const metaTags = metadata => [
  { name: "description", content: metadata.description },
  { name: "image", content: metadata?.image?.url },
  { property: "og:title", content: metadata?.title },
  { property: "og:description", content: metadata.description },
  { property: "og:type", content: metadata?.type },
  { property: "og:image", content: metadata?.image?.url },
  { property: "og:image:alt", content: metadata?.image?.description },
  { property: "og:locale", content: metadata.lang },
  { property: "og:site_name", content: metadata?.siteName },
  { property: "og:url", content: metadata?.pageUrl },
  { property: "fb:app_id", content: metadata?.facebookAppID },
  { name: "twitter:card", content: "summary_large_image" },
  { name: "twitter:creator", content: metadata?.twitterUser },
  { name: "twitter:title", content: metadata?.title },
  { name: "twitter:url", content: metadata?.pageUrl },
  { name: "twitter:description", content: metadata.description },
  { name: "twitter:image", content: metadata?.image?.url },
  { name: "twitter:image:alt", content: metadata?.image?.description },
  { name: "robots", content: metadata?.robots },
  ...(metadata?.googleSiteVerifications?.map((content: string) => ({ name: "google-site-verification", content })) || []),
]
